import classNames from "classnames";
import { motion } from "framer-motion";
import React from "react";

import { InternalExternalLink } from "../../Common/InternalExternalLink";
import { ProjectSliderNextItem, itemProps } from "../ProjectSliderShared";

import * as styles from "../ProjectSlider.module.scss";
import { useSiteContext } from "../../SiteContext";

interface ProjectNextSlideProps extends ProjectSliderNextItem {
  className?: string;
}

const ProjectNextSlide: React.FC<ProjectNextSlideProps> = ({
  className,
  items = [],
  subtitle = "Next Project",
}) => {
  const { returnToHome } = useSiteContext();

  return (
    <motion.div {...itemProps} className={className}>
      <div className={classNames(styles.itemNext)}>
        <span className={styles.nextCta}>{subtitle}</span>
        {items.map(({ to, title }) => {
          return (
            <InternalExternalLink
              key={to}
              to={to}
              state={{
                fromHome: returnToHome,
              }}
            >
              <span className={styles.nextTitle}>{title}</span>
            </InternalExternalLink>
          );
        })}
      </div>
    </motion.div>
  );
};

export { ProjectNextSlide };
