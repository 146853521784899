import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";

import Vimeo from "./embeds/Vimeo";
import Youtube from "./embeds/Youtube";

import { ProjectSliderEmbedItem, itemProps } from "../ProjectSliderShared";

import * as styles from "../ProjectSlider.module.scss";

interface ProjectEmbedSlideProps extends ProjectSliderEmbedItem {
  className?: string;
}

const SERVICES = {
  YOUTUBE: "youtube",
  VIMEO: "vimeo",
  NOT_SUPPORTED: "not_supported",
};

const ProjectEmbedSlide: React.FC<ProjectEmbedSlideProps> = ({
  className,
  embed,
}) => {
  const [loaded, setLoaded] = useState(false);

  useLayoutEffect(() => {
    setLoaded(true);
  }, []);

  const service = useMemo(() => {
    // Youtube
    if (
      embed.includes("://www.youtube.com/watch") ||
      embed.includes("://www.youtube.com/playlist") ||
      embed.includes("://youtu.be") ||
      embed.includes("://www.youtube.com/embed/live_stream") ||
      (embed.includes("youtube.com/user/") && embed.includes("/live"))
    )
      return SERVICES.YOUTUBE;

    // Vimeo
    if (embed.includes("://vimeo.com/")) return SERVICES.VIMEO;

    return SERVICES.NOT_SUPPORTED;
  }, [embed]);

  return (
    <motion.div {...itemProps} className={className}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: loaded ? 1 : 0 }}
        transition={{ duration: 0.25 }}
        className={styles.itemMedia}
        onCanPlay={() => setLoaded(true)}
      >
        {service === SERVICES.YOUTUBE && <Youtube urlUri={embed} />}
        {service === SERVICES.VIMEO && <Vimeo urlUri={embed} />}
      </motion.div>
    </motion.div>
  );
};

export { ProjectEmbedSlide };
