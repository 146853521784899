import React, { useMemo } from "react";
import { graphql, PageProps } from "gatsby";
import { getSrc, IGatsbyImageData } from "gatsby-plugin-image";
import { Project } from "../components/Project";
import {
  ProjectSliderItem,
  ProjectSliderItemType,
} from "../components/ProjectSlider";
import { SEO } from "../components/SEO";

type SanityItemImage = {
  _type: "itemimage";
  text?: string;
  image: {
    asset: {
      gatsbyImageData: IGatsbyImageData;
      url: string;
      mimeType;
    };
  };
};

type SanityItemText = {
  _type: "itemtext";
  text?: string;
  small?: boolean;
  value: string;
  // content?: any;
};

type SanityItemVideo = {
  _type: "itemnewvideo";
  text?: string;
  videofile: {
    height: number;
    width: number;
    url: string;
    size: number;
    poster: string;
  };
};
// type SanityItemVideo = {
//   _type: "itemvideo";
//   text?: string;
//   video: {
//     asset: {
//       url: string;
//     };
//   };
// };

type SanityItemEmbed = {
  _type: "itemembed";
  text?: string;
  embed: string;
};

type SanityItem =
  | SanityItemImage
  | SanityItemText
  | SanityItemVideo
  | SanityItemEmbed;

interface ProjectTemplateProps {
  nextProjects: {
    edges: Array<{
      node: {
        title: string;
        slug: {
          current: string;
        };
      };
    }>;
  };
  project: {
    title: string;
    date: string;
    external: string;
    items: SanityItem[];
    mainImage: {
      asset: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    next?: Array<{
      slug: {
        current: string;
      };
      title: string;
    }>;
  };
}

const ProjectTemplate: React.FC<PageProps<ProjectTemplateProps>> = ({
  data,
}) => {
  const items = useMemo<ProjectSliderItem[]>(() => {
    return data.project.items.map((item) => {
      switch (item._type) {
        case "itemimage":
          return {
            type: ProjectSliderItemType.Image,
            image: item.image.asset,
            description: item.text,
          };
        // case "itemvideo":
        case "itemnewvideo":
          return {
            type: ProjectSliderItemType.Video,
            video: {
              ...item.videofile,
            },
            description: item.text,
          };
        case "itemembed":
          return {
            type: ProjectSliderItemType.Embed,
            embed: item.embed,
            description: item.text,
          };
        case "itemtext":
          return {
            type: ProjectSliderItemType.Text,
            text: item.value,
            small: item.small,
            description: item.text,
          };
      }
    });
  }, []);

  const nextItem = useMemo(() => {
    if (!data.project.next && data.nextProjects.edges.length === 0) {
      return undefined;
    }

    const explicitNext = !data.project.next
      ? []
      : data.project.next.map((item) => ({
          to: item.slug.current,
          title: item.title,
        }));

    const autoNext = data.nextProjects.edges
      .map(({ node }) => {
        return {
          to: node.slug.current,
          title: node.title,
        };
      })
      .filter((item) => !explicitNext.find((i) => i.to === item.to));

    return [...explicitNext, ...autoNext].slice(0, 4);
  }, [data.project.next, data.nextProjects]);

  return (
    <>
      <SEO
        title={data.project.title}
        image={getSrc(data.project.mainImage.asset.gatsbyImageData)}
      />
      <Project
        title={data.project.title}
        year={data.project.date}
        external={data.project.external}
        items={items}
        next={nextItem}
      />
    </>
  );
};

export const query = graphql`
  query ($slug: String!, $next: [String!]!) {
    project: sanityProject(slug: { current: { eq: $slug } }) {
      mainImage {
        asset {
          gatsbyImageData(width: 1200, height: 630, formats: [JPG])
          mimeType
          url
        }
      }
      items {
        ... on SanityItemimage {
          _key
          _type
          text
          image {
            asset {
              gatsbyImageData(width: 1400)
              mimeType
              url
            }
          }
        }
        ... on SanityItemtext {
          _key
          _type
          text
          small
          value
          # content {
          #   _rawChildren
          #   children {
          #     _key
          #     _type
          #     marks
          #     text
          #   }
          #   _type
          #   list
          #   style
          #   _key
          # }
        }
        # ... on SanityItemvideo {
        #   _key
        #   _type
        #   text
        #   video {
        #     asset {
        #       url
        #     }
        #   }
        # }
        ... on SanityItemembed {
          _key
          _type
          text
          embed
        }
        ... on SanityItemnewvideo {
          _key
          _type
          text
          videofile {
            height
            width
            url
            size
            poster
          }
        }
      }
      date
      title
      external
      next {
        slug {
          current
        }
        title
      }
    }
    nextProjects: allSanityProject(
      filter: { slug: { current: { in: $next } } }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

export default ProjectTemplate;
