import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { getSrc } from "gatsby-plugin-image";

import { ProjectSliderImageItem, itemProps } from "../ProjectSliderShared";

import * as styles from "../ProjectSlider.module.scss";
import { loadImage } from "../../../utils/promises";

interface ProjectImageSlideProps extends ProjectSliderImageItem {
  className?: string;
}

const ProjectImageSlide: React.FC<ProjectImageSlideProps> = ({
  className,
  image,
}) => {
  const [loaded, setLoaded] = useState(false);

  const imageUrl = useMemo(() => {
    if (image.mimeType.includes("gif")) {
      return image.url;
    }

    return getSrc(image.gatsbyImageData) || image.url;
  }, [image]);

  useEffect(() => {
    setLoaded(false);
    if (imageUrl) {
      loadImage(imageUrl).then(() => setLoaded(true));
    }
  }, [imageUrl]);

  return (
    <motion.div {...itemProps} className={className}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: loaded ? 1 : 0 }}
        transition={{ duration: 0.25 }}
        className={styles.itemMedia}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      />
    </motion.div>
  );
};

export { ProjectImageSlide };
