import React, { useEffect, useState } from "react";

import classNames from "classnames";
import ReactPlayer from "react-player";

import * as styles from "../../ProjectSlider.module.scss";

// For Platform options see:
// https://www.npmjs.com/package/react-player

const Vimeo = ({ urlUri }) => {
  const [pt, setPt] = useState("56.25%");

  useEffect(() => {
    fetch(`https://vimeo.com/api/oembed.json?url=${urlUri}`)
      .then((resp) => resp.json())
      .then(({ width, height }) => {
        if (width && height) {
          setPt(`${Math.ceil((height / width) * 100)}%`);
        }
      });
  }, [urlUri]);

  return (
    <div
      className={styles.embed}
      style={{ "--embed-aspect": `${pt}` } as React.CSSProperties}
    >
      <ReactPlayer
        url={urlUri}
        config={{
          vimeo: {
            playerOptions: {
              byline: true,
              color: "#000099",
            },
          },
        }}
        controls={true}
        playing={false}
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default Vimeo;
