import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSwipeable } from "react-swipeable";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "@reach/router";

import * as styles from "./ProjectSlider.module.scss";
import { Markdown } from "../Common/Markdown";
import { Icon } from "../Common/Icon";
import { InternalExternalLink } from "../Common/InternalExternalLink";
import {
  ProjectSliderItem,
  ProjectSliderItemType,
} from "./ProjectSliderShared";
import { ProjectImageSlide } from "./Slides/ProjectImageSlide";
import { ProjectTextSlide } from "./Slides/ProjectTextSlide";
import { ProjectNextSlide } from "./Slides/ProjectNextSlide";
import { ProjectVideoSlide } from "./Slides/ProjectVideoSlide";
import { ProjectEmbedSlide } from "./Slides/ProjectEmbedSlide";
import { listen } from "../../utils/utils";

interface ProjectSliderProps {
  className?: string;
  items: ProjectSliderItem[];
  onItemChange?: (item: ProjectSliderItem) => void;
}

const ProjectSlider: React.FC<ProjectSliderProps> = ({
  items,
  className,
  onItemChange,
}) => {
  // const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const initialCheck = useRef(false);

  const currentItem = useMemo(() => {
    return items[currentIndex];
  }, [items, currentIndex]);

  const hasPrev = useMemo(() => {
    return currentIndex > 0;
  }, [items, currentIndex]);

  const hasNext = useMemo(() => {
    return currentIndex < items.length - 1;
  }, [items, currentIndex]);

  const goPrev = useCallback(() => {
    if (hasPrev) {
      setCurrentIndex((i) => i - 1);
    }
  }, [hasPrev]);

  const goNext = useCallback(() => {
    if (hasNext) {
      setCurrentIndex((i) => i + 1);
    }
  }, [hasNext]);

  const handlers = useSwipeable({
    onSwipedLeft: () => goNext(),
    onSwipedRight: () => goPrev(),
  });

  useEffect(() => {
    if (initialCheck.current) {
      location.hash = currentIndex.toString();
    }
  }, [currentIndex]);

  useEffect(() => {
    if (onItemChange) {
      onItemChange(currentItem);
    }
  }, [currentItem, onItemChange]);

  useEffect(() => {
    return listen(document, "keyup", (e) => {
      if (e.key === "ArrowLeft") {
        goPrev();
      }
      if (e.key === "ArrowRight") {
        goNext();
      }
    });
  }, [goPrev, goNext]);

  useEffect(() => {
    if (location.hash) {
      const slideNum = parseInt(location.hash.substring(1));

      if (!isNaN(slideNum) && slideNum < items.length - 1) {
        setCurrentIndex(slideNum);
      }
    }
    initialCheck.current = true;
  }, []);

  return (
    <div className={classNames(styles.outer, className)} {...handlers}>
      <div className={styles.controls}>
        {hasPrev && (
          <button
            className={classNames(
              styles.controlsArrow,
              styles.controlsArrowLeft
            )}
            onClick={goPrev}
          >
            <Icon name="arrow-right" />
          </button>
        )}

        {hasNext && (
          <button
            className={classNames(
              styles.controlsArrow,
              styles.controlsArrowRight
            )}
            onClick={goNext}
          >
            <Icon name="arrow-right" />
          </button>
        )}
      </div>

      <AnimatePresence exitBeforeEnter>
        {currentItem.type === ProjectSliderItemType.Image && (
          <ProjectImageSlide
            key={`slide-${currentIndex}`}
            className={classNames(styles.item, [styles[currentItem.type]])}
            {...currentItem}
          />
        )}
        {currentItem.type === ProjectSliderItemType.Video && (
          <ProjectVideoSlide
            key={`slide-${currentIndex}`}
            className={classNames(styles.item, [styles[currentItem.type]])}
            {...currentItem}
          />
        )}
        {currentItem.type === ProjectSliderItemType.Embed && (
          <ProjectEmbedSlide
            key={`slide-${currentIndex}`}
            className={classNames(styles.item, [styles[currentItem.type]])}
            {...currentItem}
          />
        )}
        {currentItem.type === ProjectSliderItemType.Text && (
          <ProjectTextSlide
            key={`slide-${currentIndex}`}
            className={classNames(styles.item, [styles[currentItem.type]])}
            {...currentItem}
          />
        )}
        {currentItem.type === ProjectSliderItemType.Next && (
          <ProjectNextSlide
            key={`slide-${currentIndex}`}
            className={classNames(styles.item, [styles[currentItem.type]])}
            {...currentItem}
          />
        )}
      </AnimatePresence>

      {currentIndex < items.length - 1 && (
        <div className={styles.indicator}>
          {currentIndex + 1}/{items.length - 1}
        </div>
      )}
    </div>
  );
};

export * from "./ProjectSliderShared";
export { ProjectSlider };
