import React, { useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";

import { ProjectSliderVideoItem, itemProps } from "../ProjectSliderShared";

import * as styles from "../ProjectSlider.module.scss";

interface ProjectVideoSlideProps extends ProjectSliderVideoItem {
  className?: string;
}

const ProjectVideoSlide: React.FC<ProjectVideoSlideProps> = ({
  className,
  video,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [muted, setMuted] = useState(true);

  return (
    <motion.div
      {...itemProps}
      className={classNames(className, styles.itemVideo)}
    >
      <motion.video
        initial={{ opacity: 0 }}
        animate={{ opacity: loaded ? 1 : 0 }}
        transition={{ duration: 0.25 }}
        className={styles.itemMedia}
        onCanPlay={() => setLoaded(true)}
        autoPlay
        muted={muted}
        src={video.url}
        loop
        playsInline
        onClick={() => setMuted(!muted)}
        poster={video.poster}
      />
      <div className={styles.volume}>
        {muted ? "Tap for volume" : "Tap to mute"}
      </div>
    </motion.div>
  );
};

export { ProjectVideoSlide };
