// extracted by mini-css-extract-plugin
export var bottom = "ProjectSlider-module--bottom--58b3c";
export var centred = "ProjectSlider-module--centred--1d8ae";
export var controls = "ProjectSlider-module--controls--ea604";
export var controlsArrow = "ProjectSlider-module--controls-arrow--c0667";
export var controlsArrowLeft = "ProjectSlider-module--controls-arrow-left--c4b76";
export var controlsArrowRight = "ProjectSlider-module--controls-arrow-right--ced36";
export var controlsLeft = "ProjectSlider-module--controls-left--7be6d";
export var controlsRight = "ProjectSlider-module--controls-right--cf8fd";
export var embed = "ProjectSlider-module--embed--b90c4";
export var indicator = "ProjectSlider-module--indicator--d1420";
export var item = "ProjectSlider-module--item--28f8a";
export var itemMedia = "ProjectSlider-module--item-media--50f34";
export var itemNext = "ProjectSlider-module--item-next--f1e66";
export var itemText = "ProjectSlider-module--item-text--1fe8b";
export var itemTextInner = "ProjectSlider-module--item-text-inner--14238";
export var itemVideo = "ProjectSlider-module--itemVideo--25787";
export var large = "ProjectSlider-module--large--8a0db";
export var next = "ProjectSlider-module--next--0a96e";
export var nextCta = "ProjectSlider-module--next-cta--bf2c1";
export var nextTitle = "ProjectSlider-module--next-title--65751";
export var outer = "ProjectSlider-module--outer--7f70b";
export var text = "ProjectSlider-module--text--68792";
export var volume = "ProjectSlider-module--volume--45251";