import React, { useCallback, useMemo, useState } from "react";
import { Markdown } from "../Common/Markdown";
import {
  ProjectSlider,
  ProjectSliderItem,
  ProjectSliderItemType,
} from "../ProjectSlider";

import * as styles from "./Project.module.scss";

interface ProjectProps {
  title?: string;
  year?: string;
  external?: string;
  items?: ProjectSliderItem[];
  next?: Array<{
    title: string;
    to: string;
  }>;
}

const Project: React.FC<ProjectProps> = ({
  title,
  year,
  external,
  items,
  next,
}) => {
  const [currentItem, setCurrentItem] = useState(null);

  const computedItems = useMemo<ProjectProps["items"]>(() => {
    const nextPost =
      next && next.length
        ? {
            type: ProjectSliderItemType.Next,
            items: next.map((i) => ({
              ...i,
              to: `/project/${i.to}`,
            })),
          }
        : {
            type: ProjectSliderItemType.Next,
            items: [
              {
                title: "Explore the map",
                to: "/",
              },
            ],
            subtitle: "Find more",
          };

    return [...items, nextPost as ProjectSliderItem];
  }, [items, next]);

  const onItemChanged = useCallback((item) => {
    setCurrentItem(item);
  }, []);

  const singleYear = useMemo(() => {
    return new Date(year).getFullYear();
  }, [year]);

  return (
    <section className={styles.outer}>
      <div className={styles.content}>
        <ProjectSlider items={computedItems} onItemChange={onItemChanged} />
      </div>
      <div className={styles.info}>
        <div className={styles.infoInner}>
          <div className={styles.infoInnerLeft}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.meta}>
              <span className={styles.year}>{singleYear}</span>
              {external && (
                <a
                  href={external}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={styles.external}
                >
                  View link
                </a>
              )}
            </div>
          </div>

          <div className={styles.infoInnerRight}>
            <Markdown className={styles.description}>
              {currentItem && currentItem.description}
            </Markdown>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Project };
