import { IGatsbyImageData } from "gatsby-plugin-image";

export enum ProjectSliderItemType {
  Image = "image",
  Video = "video",
  Embed = "embed",
  Text = "text",
  Next = "next",
}

// export type ProjectSliderVideoItem = {
//   type: ProjectSliderItemType.Video;
//   video: {
//     url: string;
//   };
//   description?: string;
// };

export type ProjectSliderVideoItem = {
  type: ProjectSliderItemType.Video;
  video: {
    url: string;
    width: number;
    height: number;
    poster: string;
  };
  description?: string;
};

export type ProjectSliderEmbedItem = {
  type: ProjectSliderItemType.Embed;
  embed: string;
  description?: string;
};

export type ProjectSliderImageItem = {
  type: ProjectSliderItemType.Image;
  image: {
    gatsbyImageData: IGatsbyImageData;
    mimeType: string;
    url: string;
  };
  description?: string;
};

export type ProjectSliderTextItem = {
  type: ProjectSliderItemType.Text;
  text: string;
  // content?: {
  //   _rawChildren: any;
  // };
  description?: string;
  small?: boolean;
};

export type ProjectSliderNextItem = {
  type: ProjectSliderItemType.Next;
  items?: Array<{
    to: string;
    title: string;
  }>;
  subtitle?: string;
};

export type ProjectSliderItem =
  | ProjectSliderImageItem
  | ProjectSliderEmbedItem
  | ProjectSliderVideoItem
  | ProjectSliderTextItem
  | ProjectSliderNextItem;

export const slideVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
  initial: {
    opacity: 0,
  },
};

export const itemProps = {
  animate: "in",
  exit: "out",
  initial: "initial",
  variants: { ...slideVariants },
};
