import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { ProjectSliderTextItem, itemProps } from "../ProjectSliderShared";
import * as styles from "../ProjectSlider.module.scss";
import { Markdown } from "../../Common/Markdown";
import { onWindowResize } from "../../../utils/events";
import { SanityBlock } from "../../Common/SanityBlock";

interface ProjectTextSlideProps extends ProjectSliderTextItem {
  className?: string;
}

const ProjectTextSlide: React.FC<ProjectTextSlideProps> = ({
  className,
  small,
  text,
  // content,
}) => {
  const { ref, inView } = useInView();
  const innerRef = useRef<HTMLDivElement | null>(null);
  const [centred, setCentred] = useState(false);

  const checkSizes = useCallback((wrapper) => {
    const child = wrapper.querySelector(`.bounding`);

    setCentred(wrapper.clientHeight > child.clientHeight);
  }, []);

  useEffect(() => {
    return onWindowResize(() => {
      if (innerRef.current) {
        checkSizes(innerRef.current);
      }
    });
  }, []);

  const onRef = useCallback((el) => {
    if (el) {
      innerRef.current = el;
      checkSizes(el);
    }
  }, []);

  return (
    <motion.div
      {...itemProps}
      className={classNames({ [styles.bottom]: inView }, className)}
    >
      <div
        className={classNames(styles.itemText, {
          [styles.large]: !small,
        })}
      >
        <div
          ref={onRef}
          className={classNames(styles.itemTextInner, {
            [styles.centred]: centred,
          })}
        >
          <div className={"bounding"}>
            {/* {content && <SanityBlock content={content} />} */}
            {text && <Markdown>{text}</Markdown>}
          </div>

          <div ref={ref} />
        </div>
      </div>
    </motion.div>
  );
};

export { ProjectTextSlide };
